.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 66.66%;
  cursor: zoom-in;
  margin: 50px 0;
}

.image-container.open {
  cursor: zoom-out;
}

.image-container .shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.9);
}

.image-container.open .shade {
  pointer-events: auto;
  opacity: 1;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.image-container.open img {
  position: fixed;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: auto;
}

button {
  padding: 0;
  border: none;
  background: none;
}

input {
  /* TIP: Remove blue border from inputs */
  outline: 0px;
  font-family: DMSans-Regular;
}

input :focus {
  outline-width: 0;
}

span {
  font-family: DMSans-Regular;
}

::placeholder {
  font-family: DMSans-Regular;
}
